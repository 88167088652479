import { ComponentRef, PageRef } from '@wix/platform-editor-sdk';

import {
  addSearchAppController,
  connectControllerWithSearchResults,
} from '../searchAppController';

import {
  addSearchResultsHeader,
  getSearchResultsComponent,
  getSearchResultsPage,
  updateSearchResultsPageData,
} from '../searchResults';

import { addSearchBox } from '../searchBox';
import { EditorAppContext } from '../types';
import { FedopsInteraction } from '../constants/interaction';

export async function doFirstInstall(appContext: EditorAppContext) {
  const { fedops, editorSDK, appDefinitionId } = appContext;
  fedops.interactionStarted(FedopsInteraction.InstallApp);

  const [searchResultsRef, searchResultsPageRef] = await Promise.all<
    ComponentRef | undefined,
    PageRef
  >([getSearchResultsComponent(appContext), getSearchResultsPage(appContext)]);

  const searchBoxRef = await installAppComponentsConsideringCE(
    appContext,
    searchResultsRef,
    searchResultsPageRef,
  );

  await editorSDK.pages.navigateTo(appDefinitionId, {
    pageRef: searchResultsPageRef,
  });

  if (searchBoxRef) {
    await editorSDK.selection.selectComponentByCompRef(appDefinitionId, {
      compsToSelect: [searchBoxRef],
    });
  }

  fedops.interactionEnded(FedopsInteraction.InstallApp);
}

async function installAppComponents(
  appContext: EditorAppContext,
  searchResultsRef: ComponentRef | undefined,
  searchResultsPageRef: PageRef,
) {
  const searchBox = await addSearchBox(appContext);
  if (searchResultsRef) {
    await addSearchResultsHeader(appContext, {
      searchResultsPageRef,
      searchResultsRef,
    });
  }

  await updateSearchResultsPageData(appContext, {
    searchResultsPageRef,
  });

  const searchAppControllerRef = await addSearchAppController(appContext);

  if (searchResultsRef) {
    await connectControllerWithSearchResults(
      appContext,
      searchAppControllerRef,
      searchResultsRef,
    );
  }

  return searchBox;
}

async function installAppComponentsConsideringCE(
  appContext: EditorAppContext,
  searchResultsRef: ComponentRef | undefined,
  searchResultsPageRef: PageRef,
) {
  const {
    experiments,
    editorSDK,
    handleException,
    appDefinitionId,
  } = appContext;
  if (experiments.enabled('specs.siteSearch.ConcurrentEditing')) {
    try {
      return await editorSDK.document.transactions.runAndWaitForApproval(
        appDefinitionId,
        async () => {
          await installAppComponents(
            appContext,
            searchResultsRef,
            searchResultsPageRef,
          );
        },
      );
    } catch (ex) {
      handleException(ex);
    }
  } else {
    return installAppComponents(
      appContext,
      searchResultsRef,
      searchResultsPageRef,
    );
  }
}
